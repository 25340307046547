/* Custom CSS */
html, body {
    overflow-x: hidden;
}
/* Platfrom CSS */
.blogpost-text .blogpost-category-link {
    margin-bottom: 0px;
}

footer a:not(.social-link) {
    color: hsla(0,0%,100%,.6);
}

    footer a:not(.social-link):hover {
        color: white;
    }

.blogpost-caption div, .blogpost-hover div, .blogpost-text div {
    display: inline-block;
}

.blogpost-caption h1, .blogpost-caption h2, .blogpost-caption h3, .blogpost-caption h4 {
    margin-top: 0px;
    margin-bottom: 7px;
}

.newsletter-section h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.w-container {
    max-width: 1170px;
    padding: 0px 20px;
}

footer h3, .banner-usp h3, .home-element-wrapper p, .center-flex p, .brandsection p {
    color: white;
    opacity: 1;
}

footer a:hover {
    color: white;
}

li p {
    opacity: 1;
}
/* Embedded page CSS */
.w-button:not(.login-btn), .green-btn, .product-list-add-to-cart-btn {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 13px 26px;
    border-radius: 4px;
    background-color: #319fd4;
    -webkit-transition: color .4s ease,background-color .4s ease,border .6s ease;
    transition: color .4s ease,background-color .4s ease,border .6s ease;
    color: #fff;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 1px 1px 6px rgba(0,0,0,.7);
    text-transform: uppercase;
}

    .w-button:not(.login-btn):hover, .w-tab-link:hover, .green-btn:hover, .product-list-add-to-cart-btn:hover {
        background-color: #111;
        color: #fff;
        text-decoration: none;
    }

.w-tab-link {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 13px 26px;
    border-radius: 4px;
    background-color: #222;
    -webkit-transition: color .4s ease,background-color .4s ease,border .6s ease;
    transition: color .4s ease,background-color .4s ease,border .6s ease;
    color: #fff;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 1px 1px 6px rgba(0,0,0,.7);
    text-transform: uppercase;
}

    .w-tab-link:hover, .w-tab-link.w--current {
        color: #fff;
        background-color: #319fd4;
    }

.pagination {
    clear: both;
    padding: 10px;
}

.product-detail .productwrapper {
    min-height: 0px;
}

.w-col.w-col-3.w-col-small-6.w-col-tiny-6 .productwrapper {
    margin-left: 5px;
    margin-right: 5px;
    width: auto;
}

.w-section {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fff;
}

h2.table-title {
    margin-top: 20px;
}

div#product-media-holder {
    margin-right: 30px;
}

.product-detail-description.p {
    margin-bottom: 30px;
}

.category-title-wrapper p, li > p {
    opacity: 1;
}

.w-container {
    max-width: 1170px;
    padding: 0px 20px;
}

select, .w-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 48px;
    border: 1px solid #ddd;
    -webkit-transition: border 600ms ease;
    transition: border 600ms ease;
    display: block;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    vertical-align: middle;
    background-color: white;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BMVEX///+GhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoabKRxlAAAAHHRSTlMAAwYSFRgeLTA8RVRXcnWNkJmcusPM2+Tn6vb8ZDtY+wAAAE1JREFUeAHtwccNxCAABMA9zjkHHAzbf51GgBAST3+ZQSrrR0RqpVsE/5O8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 98% 50%;
    border-radius: 4px;
}

    select:hover, .w-select:hover {
        border-color: #319fd4;
    }

.dropdown-toggle {
    text-transform: uppercase;
}

.product-list-header-img {
    margin-bottom: 30px;
}

.table-holder {
    margin-bottom: 20px;
}

select#node {
    margin-right: 10px;
}

.cart-remove, .price.cart-price, .cart-stock {
    margin-bottom: 7px;
}

p.cart-qty {
    display: none;
}

.shoppingcontainer .w-radio {
    padding-left: 0px;
}

.paypalAnnotation {
    margin-top: 10px;
    display: block;
}

.tabcontent {
    overflow: visible;
}

select.w-select.select.quantity.qty-tbx {
    margin-top: 10px;
}

table {
    width: 100%;
}

th {
    border: 1px solid #eee;
    padding: 10px;
}

td {
    border: 1px solid #eee;
    padding: 10px;
}

.filter-select-wrapper select {
    display: inline-block;
    width: auto;
    padding-right: 20px;
}

.variant-table .product-detail-price {
    margin-top: 20px;
}

.productwrapper a:not(.product-list-add-to-cart-btn) {
    text-decoration: none;
    color: #444;
}

.product-list-button-wrapper select#Quantity-2 {
    margin-right: 0px;
}

.w-form.product-list-button-wrapper {
    display: inline-block;
    margin-bottom: 0px;
    margin-top: 10px;
    padding-bottom: 0px;
}

.delivery-options ul {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

label.paymentLabel h4 {
    display: inline-block;
    text-transform: uppercase;
}

#Quantity-2 {
    margin-top: 9px;
    margin-right: 10px;
}

span.price-old {
    text-decoration: line-through;
}

label img {
    max-width: 90%;
}

span.fontello {
    font-family: 'Corin design', sans-serif;
}

    span.fontello.addtobasket {
        margin-right: 4px;
    }

div#discount-input {
    display: inline-block;
    position: relative;
    max-width: 363px;
    width: 100%;
    float: left;
    margin: 5px 0px;
}

span#voucher-not-found {
    color: #ea2d2d;
    float: left;
    padding: 17px
}

.w-widget {
    display: inline-block;
    float: left;
    margin-left: 10px;
}

.variant-table {
    margin-top: 30px
}

.filter-select-wrapper {
    margin-top: 10px;
}

.w-checkbox, .w-radio {
    text-align: left
}

.w-section > .w-section {
    padding: 0
}

.filter-dropdown-list {
    padding: 10px 20px;
    display: block;
    position: relative;
    background-color: white;
}

.m-filter-wrapper .subdropdown-toggle {
    background-color: white !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.w-lightbox-thumbnail {
    border: 1px solid transparent
}

.product-detail-price:nth-child(2) {
    margin-top: 10px
}

.visible-xs {
    display: none
}

.w-row.shopppingrow > div > ul {
    list-style-type: none;
    padding-left: 0
}

#paymentMethods > div > .w-radio {
    padding-left: 0
}

.qtybasket.space {
    margin-top: 0px
}

.qtybasket {
    margin-bottom: 5px;
    margin-top: 10px
}

.variant-table {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px
}

.w-col.w-col-6.text-align-right {
    text-align: right
}

.w-container.product-list-head {
    padding-bottom: 50px
}

.breadcrumb {
    margin-top: -20px;
    margin-bottom: 30px
}

.product-detail-bottom-tabs {
    margin-top: 50px
}

.clear-btn {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.m-filter-wrapper .w-dropdown-toggle {
    background-color: #eee;
    padding: 15px 20px;
    display: block;
}

.glyphicon {
    padding: 10px;
    text-decoration: none;
    position: relative;
    top: 6px;
    font-size: 17px;
    -webkit-transition: color .4s ease
}

.video-btn {
    transition: all .4s ease-in-out;
    position: absolute
}

}

.table-title {
    margin-top: 40px;
    margin-bottom: 20px;
}

.pageno {
    padding: 8px !important;
    margin-right: 0;
    text-align: center;
    background-color: transparent !important;
    text-shadow: none !important;
    color: #666 !important;
}

    .pageno:hover, .pageno.w--current {
        color: #319fd4 !important;
    }

.w-form.qtywrapper {
    margin: 0
}

label {
    display: inline-block;
    margin-right: 10px;
}

.m-filter-wrapper {
    display: block;
    background-color: transparent;
    opacity: 1;
    font-weight: 400;
    border: 1px solid #ddd;
    padding-bottom: 10px;
}

    .m-filter-wrapper .w-dropdown {
        display: block;
    }

.filter-text {
    display: inline-block;
    float: left
}

.filter-heading {
    padding: 20px;
    margin: 0;
    font-weight: 600;
    color: #444;
}

.filter-heading-icon {
    display: inline-block;
    font-family: 'Corin design',sans-serif;
    float: right;
}

.brand-filter {
    display: none
}

.w-form.qtywrapper {
    display: inline-block;
    text-align: center
}

.qty-tbx {
    display: inline-block;
    border: 1px solid #ddd;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    padding: 0 10px;
    background-color: transparent;
    height: 46px;
    width: 50px;
    /* margin: 0 auto; */
}

    .qty-tbx:focus, .qty-tbx:hover {
        border-color: #319fd4;
    }

.product-detail-price > span.price-old {
    text-decoration: line-through;
}

.productOptions {
    width: auto;
    padding: 1px 20px 1px 10px;
    ;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BMVEX///+GhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoabKRxlAAAAHHRSTlMAAwYSFRgeLTA8RVRXcnWNkJmcusPM2+Tn6vb8ZDtY+wAAAE1JREFUeAHtwccNxCAABMA9zjkHHAzbf51GgBAST3+ZQSrrR0RqpVsE/5O8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC);
    background-position: 96% 50%;
    background-repeat: no-repeat;
    border-radius: 0;
    height: 53px;
    margin-right: 10px;
    font-style: normal
}

.space {
    margin-top: 40px
}

.tabmenu {
    margin-bottom: 20px;
}

.w-row.hide {
    display: block
}

.product-tab-menu {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    float: left;
    border-right: 1px none #000;
    border-bottom: 1px none #000;
    text-align: left
}

.product-tab:first-child, .product-tab:nth-child(4n+0) {
    margin-left: 0
}

.product-tab:nth-child(3n+0) {
    margin-right: 0
}

.tabcontent {
    border: none;
    padding-top: 10px
}

.product-tab {
    border-radius: 0px;
    width: 32%;
    margin-right: 1%;
    margin-bottom: 20px;
    margin-left: 1%;
    padding: 0;
    float: left;
    background-color: #ccc;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 1px solid transparent
}

.cart-table, .video-btn, td.price {
    text-align: center
}

.product-tab:hover {
    border-color: #319fd4
}

.product-tab-img {
    width: 100%
}

.w-tab-pane.product-media.w--tab-active img {
    min-width: 100%;
}

.video-btn {
    left: 0;
    right: 0;
    display: inline-block;
    width: 44px;
    height: 44px;
    margin: 50% auto;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #319fd4;
    font-size: 19px;
    padding-left: 3px;
    border: 1px solid #319fd4
}

    .video-btn:hover {
        background-color: transparent;
        color: #319fd4;
        border-color: #319fd4
    }

.productcontent {
    cursor: zoom-in;
    margin-top: 3px;
    margin-bottom: 10px
}

.product-image {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-bottom: 18px;
    margin-left: auto
}

.checkboxfield img, .filter-heading.mobile, .product-detail-slider, .subdropdown-toggle > .w-icon-dropdown-toggle {
    display: none
}

div#add-to-bag {
    margin-bottom: 40px;
}

.spec-table {
    margin-bottom: 30px;
}

    .spec-table tr:nth-child(2n) {
        background-color: #eee;
    }

.filter-heading.mobile {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border: 1px solid transparent;
    background-color: #222;
    color: #fff;
    padding: 12px 20px;
    margin-bottom: 20px;
    border-radius: 4px;
}

    .filter-heading.mobile:hover {
        border-color: transparent;
        background-color: #319fd4;
        color: #fff;
    }

#product-variants-table td:first-child {
    width: 50%
}

#product-variants-table td {
    padding: 0 5px
}

.product-info td {
    color: #828282;
    padding: 0 10px 0 0;
    font-weight: 300
}

    .product-info td:last-child {
        text-align: center;
        min-width: 91px
    }

td.price {
    width: 30%
}

label.paymentLabel {
    float: none
}

.cart-table {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px
}

#checkout .row, .shopppingrow {
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #eee;
}

    #checkout.row:first-child {
        border-bottom: none
    }

    #checkout .row:last-child, .shopppingrow:last-child {
        border-bottom: none;
    }

#delivery-options-frame h4 {
    margin-top: 0
}

#checkout .checkboxfield {
    padding-left: 0
}

.cart-table tr td:nth-child(7), .cart-table th:nth-child(7) {
    display: none
}

.w-tab-pane h2 {
    text-align: left
}

.w-form.results-filter-wrapper {
    float: right;
}

.productwrapper {
    min-height: 435px;
    color: #444;
    margin-left: auto;
    margin-right: auto;
}

.new, .product-name {
    font-family: Montserrat,sans-serif;
}

@media (max-width: 991px) {
    .w-container {
        padding: 0px 10px;
    }

    div#product-media-holder {
        margin-right: 0px;
    }
}

@media (max-width: 767px) {
    .productwrapper {
        min-height: 510px;
    }

    .results-filter-wrapper label {
        display: none;
    }

    .w-section {
        overflow: hidden;
        padding: 50px 10px;
    }

    .w-container {
        padding: 0px;
    }

    .pagination {
        padding: 40px 10px 0px 10px;
        clear: both;
    }

    h1.productListingMainHeading, .breadcrumb {
        padding-left: 10px;
    }

    .product-detail-slider-arrow {
        color: #ddd;
    }

        .product-detail-slider-arrow:hover {
            color: #08436b;
        }

    .qtybasket.space {
        text-align: left;
    }

    .product-detail-description.p {
        margin-bottom: 30px;
    }

    .m-filter-wrapper {
        display: none;
        opacity: 0;
    }

    td.visible-xs.text-right {
        width: 13%;
    }

    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: table-cell;
    }

    .w-container.product-list-head {
        padding-bottom: 20px;
    }

    .product-detail-price {
        display: block;
        margin-top: 20px;
    }

    .variant-table {
        margin-bottom: 30px;
    }

    .product-detail-bottom-tabs {
        margin-top: 30px;
    }

    .product-detail-slider {
        display: block;
        height: auto;
        background-color: transparent;
        padding-bottom: 20px;
        display: block;
        opacity: 1;
        margin-top: 20px;
    }

    .filter-heading.mobile {
        display: block;
    }

    .w-row.hide {
        display: none;
        background-color: #fff;
    }

    .tab, .tab.w--current, .tab.pf-tab-header {
        width: 100%;
    }

    .tabs {
        display: none;
    }

    .breadcrumb {
        margin-top: 0px;
    }

    .tabcontent {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    #node, #product-sort {
        margin-top: 0px;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .tabmenu {
        margin-bottom: 0px;
    }

    .w-slide.product-detail-slide {
        padding: 0px 0px 20px 0px;
    }
}

@media screen and (max-width: 479px) {
    .col, .w-col .w-col {
        width: 100%
    }

    .productwrapper {
        min-width: 100%;
    }
}
/*Blas's CSS*/
.topheader {
    padding: 0;
}

.ui-autocomplete.ui-front.ui-menu {
    z-index: 999999 !important;
    opacity: 0.95;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 26px;
    border-radius: 4px;
    background-color: #319fd4;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 700;
    letter-spacing: 1px;
    opacity: 1 !important;
}

    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        background-color: #111;
        color: #fff;
        text-decoration: none;
        opacity: 1;
    }

.button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 13px 26px;
    border-radius: 4px;
    background-color: #319fd4;
    -webkit-transition: color .4s ease,background-color .4s ease,border .6s ease;
    transition: color .4s ease,background-color .4s ease,border .6s ease;
    color: #fff;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 1px 1px 6px rgba(0,0,0,.7);
    text-transform: uppercase;
}

    .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        background-color: #111;
        color: #fff;
        text-decoration: none;
    }

.banner .editImageX {
    top: 40px !important;
    left: 150px !important;
    position: relative;
    float: left;
}

.img-overlay img {
    height: 600px;
    background-size: cover;
    background-position: 50% 50% !important;
    width: 100%;
}

.img-overlay {
    display: block !important;
    background-size: cover;
    background-position: 50% 50% !important;
}

@media (max-width: 991px) {
    .flex-child.small {
        width: 31%;
    }
}

@media (max-width: 767px) {
    .flex-child.small {
        width: auto;
    }
}

.blog-layout-full-width-featured-posts .blogpost-category-link {
    position: relative;
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 15px;
    padding: 7px 14px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #222;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
}

    .blog-layout-full-width-featured-posts .blogpost-category-link:hover {
        background-color: #319fd4;
        color: #fff;
        text-shadow: none;
    }

.categoryBlog {
    list-style: none;
}

.slider-container p {
    opacity: 1;
}

.cta-bg.cta.section .editImageX {
    top: 0px !important;
    left: 36px !important;
}

.legal-info .redactor-toolbar-box {
    left: -150px;
}
/*IE Explorer Fix*/
.banner-container.w-container {
    max-width: inherit;
}

.button-wrapper p {
    opacity: 1;
}

.blog-layout-full-width-featured-posts .blogpost-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 20px 13px;
    background-color: rgba(0,0,0,.4) !important;
    color: #fff;
}

.productname {
    color: #444 !important;
}

#discount-btn {
    right: 0;
    top: 0px;
    padding: 9.5px;
    margin-top: 0;
}

.cta-bg.cta.section .redactor-toolbar-box {
    left: 50px !important;
}

.cta-bg.cta.section .mbss-textArea-btns {
    left: 50px !important;
}

select::-ms-expand {
    display: none;
}

.megaheading {
    -webkit-transition: color 500ms ease, background-color 500ms ease;
    transition: color 500ms ease, background-color 500ms ease;
    color: #666;
    white-space: normal !important;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

    .megaheading:hover {
        color: #319fd4;
        text-decoration: none;
    }

    .megaheading.w--current {
        color: #319fd4;
    }

        .megaheading.w--current:hover {
            color: #319fd4;
        }

.filter-dropdown .w-dropdown-toggle {
    white-space: normal;
}

.filter-subdropdown-list {
    position: relative;
    background: white;
}

.filter-cats .w-icon-dropdown-toggle {
    right: -30px !important
}

.megalink.w--current {
    color: #319fd4;
}

    .megalink.w--current:hover {
        color: #319fd4;
    }

.product-list-header-img {
    margin-bottom: 30px;
    max-height: 500px;
}

.col-list-prod {
    text-align: center;
}

.minislider-section {
    display: block;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    box-shadow: inset 0 6px 20px -20px rgba(0,0,0,.2);
}

    .minislider-section .addListItem2Btn {
        bottom: 0px;
    }

.banner-container.w-container {
    max-width: 80% !important;
}

.minislider-section .mini-slider {
    opacity: 1 !important;
}

.minislider-section .mini-slider-img {
    padding: 20px 15px !important;
}

.minislider-section .leftarrow, .minislider-section .rightarrow {
    opacity: 0.6;
}

.minislider-section .miniarrow:hover {
    color: #319fd4;
}

.minislider-section .deleteListItem2Btn {
    left: 0 !important;
}

@media (max-width: 1200px) {
    .section.cta {
        height: 85vh;
    }
}

@media (max-width: 520px) {
    .banner-container.w-container p {
        font-size: 14px;
    }

    .banner-container.w-container {
        max-width: 84% !important;
    }

    .section.cta {
        height: 200vh;
    }
}

.flex-child img {
    height: auto !important;
    min-height: 300px;
}

.flex-child .img-overlay {
    min-height: 300px;
    height: auto !important;
}

.flex-child h2 {
    line-height: 30px;
}

#cookie-bar.fixed {
    z-index: 900;
}

.productwrapper {
    min-height: inherit !important;
    color: #444;
}

.lg-backdrop.in {
    z-index: 9999999 !important;
}

.lg-outer.lg-visible {
    z-index: 9999999 !important;
}

.nav-link.mid.left {
    float: left;
}

.searchform-wrapper {
    max-width: 240px;
}

.searchform {
    margin-right: 20px;
}

.telephone ul.mbss-textArea-btns {
    top: 80%;
}

.telephone.mbss-text ul.redactor-toolbar {
    top: 0px !important;
    right: 115% !important;
    left: unset !important;
}

@media (max-width: 991px) {
    .nav-link.mid.left {
        float: right;
    }

    .searchform-wrapper {
        max-width: 100%;
    }
}
/*6.2.11*/
.pf-page-wrapper .pf-tab-header {
    background-color: #222;
}

    .pf-page-wrapper .pf-tab-header:hover {
        color: #fff;
        background-color: #319fd4;
    }

    .pf-page-wrapper .pf-tab-header.w--current {
        background-color: #319fd4 !important;
    }

.productname {
    color: #444 !important;
}

.dropdown-toggle.pf-filter-item {
    background-color: #eee;
    padding: 15px 20px;
}

.pf-filter-heading {
    padding: 20px;
    margin: 0;
    font-weight: 600;
    color: #444;
    font-size: 18px;
}

.pf-filter-item a {
    color: #319fd4;
}

a.pf-normal-link {
    transition: color .5s ease;
    color: #319fd4;
    text-decoration: none;
}

    a.pf-normal-link:hover {
        color: #444;
        text-decoration: none;
    }

.pf-icon-link {
    transition: color .5s ease;
    color: #319fd4 !important;
    text-decoration: none;
}

    .pf-icon-link:hover {
        color: #444 !important;
        text-decoration: none;
    }

.pf-btn.pf-btn-primary, .pf-btn.pf-btn-secondary {
    /*margin-top: 10px;*/
    background-color: #319fd4;
    margin-bottom: 10px;
    padding: 13px 26px;
    border-radius: 4px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fff;
    text-shadow: 1px 1px 6px rgba(0,0,0,.7);
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border: 0;
}

    .pf-btn.pf-btn-primary:hover, .pf-btn.pf-btn-secondary:hover {
        background-color: #222;
    }

.pf-modal-wishlist .pf-btn {
    padding: 10px 20px;
}

.wishlistCheckboxList .checkbox label {
    width: 100%;
}

.pf-btn.post-edit-wishlist-btn, .pf-btn.cancel-edit-wishlist-name-btn {
    padding: 4px 10px;
}

#pf-product-details-page .pf-product-image-thumb.w--current {
    border: 1px solid #319fd4;
}

.pf-qty-btn.pf-qty-btn-minus {
    border: 1px solid #ddd;
}

.pf-qty-btn.pf-qty-btn-plus {
    border: 1px solid #ddd;
}

.pf-btn.pf-btn-primary.pf-btn-with-qty {
    height: 46px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.pf-qty-btn {
    height: 46px;
}

.pf-favs-table .pf-qty-btn {
    font-size: 18px;
}

.pf-favs-table .qtybasket {
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 16px;
}

#discount-btn {
    top: -4px;
    padding: 8.5px 10px;
}

.checkout-cart-footer-row-label {
    display: block !important;
}

th {
    border: 0px solid #eee;
}

.w-col.w-col-3.w-col-small-6.w-col-tiny-6 .productwrapper {
    width: 100%;
}

.pf-page-wrapper .pf-tabs-top {
    border-bottom: unset !important;
}

.pf-btn.pf-btn-default {
    margin-top: 10px;
    background-color: #fff;
    display: inline-block;
    margin-bottom: 10px;
    padding: 13px 26px !important;
    border-radius: 4px;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #319fd4;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border-color: #319fd4;
}

    .pf-btn.pf-btn-default:hover {
        background-color: black;
        color: white;
        border: unset;
    }

#pf-clear-filters-btn:hover, .pf-filter-item a:hover {
    text-decoration: none !important;
}

#pf-clear-filters-btn {
    display: block !important;
    width: 50% !important;
    color: white !important;
}

#discount-btn {
    top: -5px;
    padding: 8.5px 10px !important;
}

@media screen and (max-width: 479px) {
    .product-block .w-col-tiny-6 {
        width: 100%;
    }
}

.topnav {
    display: block;
    border-top: 3px none #999;
    background-color: #319fd4;
    text-align: right;
}

.nav-link.top {
    padding: 9px 10px;
    color: hsla(0, 0%, 100%, .6);
    font-size: 12px;
    text-transform: none;
}

.topnav .nav-link.top a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    color: hsla(0, 0%, 100%, .6);
}

    .topnav .nav-link.top a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        color: #fff;
    }

.topnav.w-nav {
    z-index: unset !important;
}

.pf-recent-related-product-item {
    box-shadow: unset;
}

    .pf-recent-related-product-item:hover {
        box-shadow: unset;
    }

.pf-qty-btn {
    width: 19px;
}

.pf-qty-select {
    width: 47px;
}

.pf-terms-wrapper a:hover, .pf-terms-wrapper a {
    color: #319fd4;
}

@media (max-width: 940px) {
    .mega-list.w--open {
        left: -100%;
    }
}

@media (max-width: 770px) {
    .checkout-cart-footer-padding-total-tax {
        display: table-cell !important;
    }
}

@media (max-width: 480px) {
    .cart-table tr td:nth-child(1) {
        padding: 0px;
        width: 40%;
    }

    .pf-qty-btn {
        width: 17px;
    }

    .pf-qty-select {
        width: 30px;
    }

    .pf-checkout-cart-table td {
        padding: 1px !important;
    }
}

@media (min-width: 768px) {
    table.cart-table tbody > tr > td, table.cart-table tbody > tr > th {
        padding: 1px !important;
    }
}

.mega-list.w--open {
    left: 0px;
}

@media (max-width: 991px) {
    .mega-list.w--open {
        left: -100%;
    }
}

@media (min-width: 992px) {
    .mega-list.w--open {
        left: -50%;
    }
}

.pf-product-images-wrapper-slider .pf-product-images-slider-arrow {
    background-color: transparent;
    opacity: 1;
}

#prod-search-tbx {
    padding-right: 32px;
}

#scrollUp {
    border-radius: 50%;
    background-position-x: -1px;
}

#edit-details-btn, #save-address-btn {
    margin-left: 10px;
}

.pf-filter-header .pf-filter-main-dropdown-btn,
.pf-filter-wrapper .pf-filter-dropdown-btn {
    margin-top: 15px;
}

.price-note {
    margin-top: 10px;
    font-size: 15px;
}

.homepageBanner .banner-link {
    background-color: transparent !important;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
}

.pf-btn {
    height: unset;
}

    .pf-btn.pf-btn-colour-one:hover {
        background-color: #319fd4;
    }

.pf-input.pf-hover-colour-one:focus, .pf-input.pf-hover-colour-one:hover, .pf-input.pf-hover-colour-two:focus, .pf-input.pf-hover-colour-two:hover {
    border-color: #319fd4;
}
.pf-quick-order-top .pf-qty-btn {
    height: 34px;
}
.pf-quick-order-results-wrapper .pf-btn.pf-btn-primary.pf-btn-with-qty {
height:34px;
padding:9px 26px;
}
.pf-quick-add-to-cart-btn.pf-btn.pf-btn-sm.pf-btn-with-qty.pf-btn-colour-one {
padding:6px 10px;
}
.w-section {
    padding-top: 10px;
}

    @media (max-width: 1300px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 169px !important;
        }
    }

    @media (max-width: 1200px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 155px !important;
            width: 90px;
        }
    }

    @media (max-width: 990px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 135px !important;
        }
    }

    @media (max-width: 915px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 125px !important;
        }
    }

    @media (max-width: 850px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 110px !important;
        }
    }

    @media (max-width: 765px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 55% !important;
        }
    }

    @media (max-width: 600px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 50% !important;
        }
    }

    @media (max-width: 479px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 55% !important;
        }
    }

    @media (max-width: 292px) {
        .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
            top: 46% !important;
        }
    }

.topheader{
    padding-top:0 !important;
}
.w-inline-block + div {
    height: 200px !IMPORTANT;
}

.footerelementwrapper.SiteLinks {   
    padding-left: 120px;
    text-align: left;
}

#pf-my-account-page .pf-add-to-cart-btn {
    margin-left: 10px;
}
    

@media screen and (min-width:100px) and (max-width:768px) {
    .footerelementwrapper.SiteLinks {
        text-align: left;        
        padding-left: 0px;
    }
      
}


@media screen and (min-width:768px)and (max-width:1200px) {
    .footerelementwrapper.SiteLinks {
        padding-left: 40px;
        text-align: left;
    }
}

@media (max-width: 770px) {
    h1 {
        font-size: 20px;
    }

    .flex-child.small {
        width: 100%;
    }

    .flex-child .img-overlay, .flex-child img {
        max-width: 330px;
        margin: auto;
    }

    .w-inline-block + div {
        min-height: inherit;
        position: inherit;
    }
}
#create-account-form #create-btn {
    position: relative;
    top: 90px;
    right: 26.9%;
}
.h-captchawarning {
    color:#DC143C;
}
    